<template>
  <Modal
    xl
    close
    headerBorder
    footerBorder
    :show-modal="showAddTrainingSegments"
    :loading="loading"
    @close="toggleAddTrainingSegments"
  >
    <template #header>
      <TitleRegular
        neutral
        :title="$t('customer.training_data.tm_training.add_segment.title')"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-6 mt-6">
        <TextTiny lightNeutral>{{
          $t('customer.training_data.tm_training.add_segment.info')
        }}</TextTiny>
        <Select
          :title="$t('customer.language_assets.translation_memory.title')"
          class="w-full tabletPortrait:max-w-[400px]"
        >
          <SelectSelectionCustom
            data-cy="tm_select"
            :loading="loadingAssets"
            :allowSearch="sharedTms.length > 10"
            :placeholder="
              $t('customer.language_assets.translation_memory.title')
            "
            :value="
              sharedTms.find((t) => t.id === selectedTm)?.attributes?.name || ''
            "
            @search="(value) => (tmSearch = value)"
          >
            <SelectOptionCustom
              v-for="tm in filteredTms"
              :key="tm.id"
              :value="tm.id"
              :selected="selectedTm === tm.id"
              @inputChange="onTm"
            >
              <span>{{ tm.attributes.name }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
        <TrainingTable
          :source="currentTm.attributes.source_language"
          :target="currentTmLanguage"
          :actions="false"
        >
          <TrainingTableRow
            v-for="(segment, i) in tmSegments"
            :key="i"
            :segment="segment"
            :actions="false"
          />
        </TrainingTable>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="$t('customer.training_data.tm_training.add_segment.btn')"
          class="flex-1"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="toggleAddTrainingSegments"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import TrainingTable from '@/components/languageAssets/trainingData/TrainingTable'
import TrainingTableRow from '@/components/languageAssets/trainingData/TrainingTableRow'
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const loadingAssets = ref(false)
const tmSearch = ref(null)
const selectedTm = ref(null)

const sharedTms = computed(() => store.state.translationMemories.sharedTms)
const currentTm = computed(() => store.state.translationMemories.currentTm)
const currentTmLanguage = computed(
  () => store.state.translationMemories.currentTmLanguage
)
const tmSegments = computed(() => store.state.translationMemories.tmSegments)
const showAddTrainingSegments = computed(
  () => store.state.modals.showAddTrainingSegments
)

const filteredTms = computed(() => {
  return sharedTms.value
    .filter(
      (tm) =>
        !tmSearch.value ||
        tm.attributes.name.toLowerCase().includes(tmSearch.value.toLowerCase())
    )
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
})

const toggleAddTrainingSegments = () =>
  store.commit('modals/toggleAddTrainingSegments')

function onTm(value) {
  selectedTm.value = value
}

watch(
  () => currentTm.value,
  () => {
    selectedTm.value = currentTm.value.id
  }
)
</script>
