<template>
  <ModalDeleteFolder
    :show="showDeleteModal"
    :folder-name="data.attributes.name"
    :folder-id="data.id"
    @close="closeDeleteModal"
  />
  <tr
    data-cy="folder_card"
    class="group !bg-neutral-50 cursor-pointer"
    :class="{ '!bg-white': whiteBg }"
    @click="onClick"
  >
    <TableCell>
      <div class="flex items-center gap-2">
        <InlineSvg
          class="h-full [&>g>g>path]:!fill-neutral-100 [&>g>g>path]:!opacity-100"
          :src="require('@/assets/icons/folder-icon.svg')"
          alt="dot"
          width="20"
        />
        <TextSmall medium truncate>
          {{ data.attributes.name }}
        </TextSmall>
      </div>
    </TableCell>
    <TableCell>
      <OutputText truncate :text="formatDate" />
    </TableCell>
    <TableCell>
      <OutputText
        truncate
        :text="
          $t('folders.folder.project_count', {
            count: data.attributes.total_projects
          })
        "
      />
    </TableCell>
    <TableCell overflowVisible>
      <ButtonEditCard
        class="!relative ml-auto !opacity-0 group-hover:!opacity-100"
        :data-cy="`folder_${data.attributes.name}_edit_card`"
      >
        <template #option>
          <span @click.prevent.stop="openModal(data)">
            {{ $t('shared.button.rename_folder') }}
          </span>
          <span
            class="!text-error-100"
            :data-cy="`delete_${data.attributes.name}`"
            @click.prevent.stop="openDeleteModal"
          >
            {{ $t('shared.button.delete_folder') }}
          </span>
        </template>
      </ButtonEditCard>
    </TableCell>
  </tr>
</template>
<script setup>
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TextSmall from '@/components/shared/font/text/TextSmall'
import ModalDeleteFolder from '@/components/shared/modals/ModalDeleteFolder'
import OutputText from '@/components/shared/output/OutputText'
import TableCell from '@/components/shared/table/TableCell'
import i18n from '@/i18n'
import moment from 'moment'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const router = useRouter()

const props = defineProps({
  data: Object,
  whiteBg: Boolean
})

const showDeleteModal = ref(false)
const formatDate = computed(() =>
  moment(props.data.attributes.created_at).format('YYYY-MM-DD')
)

const setRenameFolder = (payload) =>
  store.commit('workspace/setRenameFolder', payload)
const toggleRenameModal = (payload) =>
  store.commit('modals/toggleRenameModal', payload)

function onClick(e) {
  router.push({
    name: 'clientFolder',
    params: { id: props.data.id }
  })
}
function openModal(folder) {
  toggleRenameModal(true)
  setRenameFolder({ id: folder.id, name: folder.attributes.name })
}
function openDeleteModal() {
  showDeleteModal.value = true
}
function closeDeleteModal() {
  showDeleteModal.value = false
}
</script>
