<template>
  <div
    class="grid grid-cols-[repeat(auto-fill,_minmax(15.625rem,_1fr))] auto-rows-auto grid-flow-dense gap-x-5 gap-y-5 pb-8 tabletPortrait:grid-cols-[repeat(auto-fill,_minmax(18.75rem,_1fr))]"
    :class="{ '!grid-cols-1 !gap-y-2.5': showListView }"
  >
    <SectionCard v-if="showListView">
      <Table :columns="columns">
        <slot />
      </Table>
    </SectionCard>
    <template v-else>
      <slot />
    </template>
  </div>
</template>
<script setup>
import SectionCard from '@/components/shared/section/SectionCard'
import Table from '@/components/shared/table/Table'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const showListView = computed(() => store.state.account.showListView)

const columns = ref([
  {
    field: 'name',
    label: $t('shared.label.name'),
    width: 100
  },
  {
    field: 'date_created',
    label: $t('shared.label.date_created'),
    width: 80
  },
  {
    field: 'number_projects',
    label: $t('shared.label.number_projects'),
    width: 60
  },
  {
    field: 'actions',
    label: '',
    width: 30
  }
])
</script>
