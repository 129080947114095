<template>
  <div class="relative text-neutral-100">
    <Label
      v-if="title"
      :required="required"
      :optional="optional"
      :title="title"
      :tooltip-text="tooltipText"
      :tooltipHtml="tooltipHtml"
      :tooltip-position="tooltipPosition"
      tooltip-small
    />
    <div
      class="relative h-[120px] w-full border border-neutral-80 bg-neutral-50 rounded-lg cursor-pointer"
    >
      <input
        id="assetsFieldHandle"
        ref="file"
        class="absolute m-0 p-0 left-0 w-full h-full outline-none opacity-0 cursor-pointer z-[2]"
        data-cy="assets_dnd_field"
        type="file"
        :multiple="multiple"
        :accept="customSupportedFormats || supportedFormats"
        @drop="drop($event)"
        @change="onChange($event)"
        @click="resetUpload"
      />
      <label
        for="assetsFieldHandle"
        class="flex flex-col justify-center items-center h-full"
      >
        <i class="fas fa-file-upload text-3xl mb-3" />
        <span>{{ text }}</span>
      </label>
    </div>
    <template v-if="files.length > 0">
      <FilterWrap>
        <FilterItem
          v-for="(file, i) in files"
          :key="i"
          @remove="onRemoveFile(i)"
        >
          <span>{{ file.name }}</span>
        </FilterItem>
      </FilterWrap>
    </template>
    <small>
      <div v-if="customSupportedFormats" class="mt-3">
        <font-awesome-icon icon="fa-circle-info" />
        {{ $t('shared.label.supported_formats') }}:
        {{ customSupportedFormats }}
      </div>
    </small>
  </div>
</template>
<script setup>
import FilterItem from '@/components/shared/filter/new/FilterItem.vue'
import FilterWrap from '@/components/shared/filter/new/FilterWrap.vue'
import Label from '@/components/shared/label/Label'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const props = defineProps({
  text: String,
  required: Boolean,
  optional: Boolean,
  title: String,
  tooltipText: String,
  tooltipHtml: String,
  tooltipPosition: String,
  multiple: {
    type: Boolean,
    default: true
  },
  customSupportedFormats: { type: String, default: '' }
})
const emit = defineEmits(['filesAdded', 'removeFiles'])

const files = ref([])
const file = ref(null)

const supportedFileFormats = computed(
  () => store.state.account.supportedFileFormats
)
const supportedFormats = computed(
  () => `.${supportedFileFormats.value.toString().replaceAll(',', ', .')}`
)
const acceptedExtensions = computed(() =>
  props.customSupportedFormats
    ? props.customSupportedFormats
    : supportedFileFormats.value
)

watch(
  () => files.value,
  (newFiles) => {
    emit('filesAdded', newFiles)
  },
  { deep: true }
)

const warning = (payload) => store.dispatch('toast/warning', payload)

function onRemoveFile(i) {
  files.value.splice(i, 1)
  emit('removeFiles', i)
}
function resetUpload() {
  file.value = ''
}
function onChange(event) {
  if (!props.multiple) {
    validateFile(event.target.files[event.target.files.length - 1])
  } else {
    const newFiles = [...event.target.files]
    for (const f of newFiles) {
      validateFile(f)
    }
  }
}
function drop(event) {
  event.preventDefault()
  const newFiles = [...event.dataTransfer.files]
  for (let i = 0; i < newFiles.length; i++) {
    validateFile(newFiles[i])
  }
}

function validateFile(newFile) {
  if (
    acceptedExtensions.value.includes(
      newFile.name.split('.')[newFile.name.split('.').length - 1]
    )
  ) {
    if (!props.multiple) files.value = [newFile]
    else files.value.push(newFile)
  } else {
    warning({
      title: $t('shared.toast.warning.title'),
      message: $t('shared.toast.message.file.notsupported')
    })
  }
}
</script>
