<template>
  <label
    :for="id"
    class="flex flex-col text-neutral-200 text-sm"
    :class="{ '!flex-row justify-between items-center': horizontal }"
    @click.prevent
  >
    <Tooltip
      v-if="tooltipText || tooltipHtml"
      grey-theme
      :label="tooltipLabel"
      :description="tooltipText"
      :html="tooltipHtml"
      :position="tooltipPosition"
      :small-size="tooltipSmall"
      :large-size="tooltipLarge"
      :class="{ 'flex-1': horizontal }"
    >
      <div class="flex items-center pb-1">
        <TextRegular
          v-if="main"
          bold
          neutral
          class="w-full border-b border-neutral-80 pb-1 !mb-2"
          >{{ title }}</TextRegular
        >
        <TextSmall v-else medium neutral>
          {{ title }}
        </TextSmall>
        <span
          v-if="props.required || props.optional"
          :class="{ 'ml-1 italic text-xs text-neutral-100': optional }"
        >
          {{ indicator }}
        </span>
        <font-awesome-icon
          icon="fa-circle-info"
          class="text-neutral-100 ml-1"
        />
      </div>
    </Tooltip>
    <div
      v-if="title && !tooltipText && !tooltipHtml"
      class="flex items-center pb-1"
    >
      <TextRegular
        v-if="main && title"
        bold
        neutral
        class="w-full border-b border-neutral-80 pb-1 !mb-2"
        >{{ title }}</TextRegular
      >
      <TextSmall v-if="!main && title" medium neutral>
        {{ title }}
      </TextSmall>
      <span
        v-if="props.required || props.optional"
        :class="{ 'ml-1 italic text-xs text-neutral-100': optional }"
      >
        {{ indicator }}
      </span>
    </div>
    <div class="flex flex-col gap-3">
      <slot />
    </div>
  </label>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextSmall from '@/components/shared/font/text/TextSmall'
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
import { computed } from 'vue'

const $t = i18n.t

const props = defineProps({
  title: String,
  id: String,
  tooltipLabel: String,
  tooltipText: String,
  tooltipHtml: String,
  tooltipPosition: {
    type: String,
    default: 'right'
  },
  tooltipSmall: {
    type: Boolean,
    default: false
  },
  tooltipLarge: {
    type: Boolean,
    default: false
  },
  required: Boolean,
  optional: Boolean,
  horizontal: Boolean,
  main: Boolean,
  slotsGap: Boolean
})

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})
</script>
