<template>
  <div>
    <slot name="label" />
    <div class="flex relative">
      <input
        :id="id"
        v-model="inputValue"
        :data-cy="id"
        type="email"
        class="block w-full px-2.5 py-2 pr-8 text-black placeholder:text-neutral-100 text-sm bg-neutral-50 border border-neutral-80 rounded-lg focus:border-primary-100 hover:border-primary-100 focus:ring-0"
        :class="{
          'bg-white': !disabled,
          '!text-error-100 !border-error-100':
            showValidationWarning || publicDomain
        }"
        :disabled="disabled"
        autocomplete="email"
        :placeholder="placeholder ? `${placeholder}${indicator}` : ''"
      />
      <template v-if="withIcon && !showValidationWarning">
        <div class="absolute right-2.5 bottom-2">
          <i class="fas fa-envelope" />
        </div>
      </template>
      <template v-if="showValidationWarning">
        <div class="absolute right-2.5 bottom-2">
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="text-error-100"
          />
        </div>
      </template>
    </div>
    <template v-if="showValidationWarning || publicDomain">
      <p class="mt-2 text-sm text-error-100">
        {{
          publicDomain
            ? $t('shared.validation.password.business_email')
            : $t('shared.label.invalid_email')
        }}
      </p>
    </template>
  </div>
</template>
<script setup>
import { validateEmail } from '@/helpers/validation'
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, onBeforeMount, ref, watch } from 'vue'

const $t = i18n.t

const emit = defineEmits(['validate', 'inputChange'])
const props = defineProps({
  keyName: String,
  type: {
    type: String,
    default: 'text'
  },
  id: String,
  title: String,
  value: String,
  withIcon: Boolean,
  placeholder: String,
  optional: Boolean,
  required: Boolean,
  disabled: Boolean,
  withValidation: {
    type: Boolean,
    default: true
  },
  publicDomain: {
    type: Boolean,
    default: false
  }
})

const inputValue = ref('')
const isValid = ref(true)
const originalValue = ref('')

const showValidationWarning = computed(
  () => props.withValidation && !isValid.value
)

const indicator = computed(() => {
  if (props.required) return '*'
  if (props.optional) return ` (${$t('shared.label.optional')})`
  return ''
})

watch(
  () => inputValue.value,
  (val) => {
    if (val !== originalValue.value) {
      onValidateEmail(val)
    } else {
      isValid.value = true
      emit('validate', true)
    }
    if (val == props.value) return
    emit('inputChange', { value: val, field: props.keyName })
  }
)

watch(
  () => props.value,
  (val) => {
    onValidateEmail(val)
    if (val === inputValue.value) return
    inputValue.value = val
  }
)

onBeforeMount(() => {
  inputValue.value = props.value
  originalValue.value = props.value
})

const onValidateEmail = _.debounce(function (email) {
  isValid.value = validateEmail(email)
  emit('validate', isValid.value)
}, 500)
</script>
