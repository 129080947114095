<template>
  <Modal :show-modal="showInviteUserModal" @close="toggleInviteUserModal">
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('customer.users_permissions.modal.title')"
      />
      <TextSmall class="text-center pt-1">{{
        $t('customer.users_permissions.modal.text')
      }}</TextSmall>
    </template>
    <template #body>
      <Label required :title="$t('shared.label.email_address')">
        <InputFieldEmail
          id="user_email_input"
          :placeholder="$t('shared.label.email')"
          @inputChange="handleEmail"
        />
        <p v-if="userIsInvited" class="text-sm text-error-100 mt-1">
          {{ $t('shared.label.user_is_invited') }}
        </p>
      </Label>
    </template>
    <template #footer>
      <RequiredInfoLabel />
      <BtnsWrapperVertical>
        <BtnPrimary
          class="w-full"
          :disabled="disableButton"
          :title="$t('customer.users_permissions.modal.btn.send')"
          @click="onSend(email)"
        />
        <BtnSecondary
          :title="$t('shared.button.close')"
          @click="toggleInviteUserModal"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputFieldEmail from '@/components/shared/input/InputFieldEmail'
import Label from '@/components/shared/label/Label'
import RequiredInfoLabel from '@/components/shared/label/RequiredInfoLabel'
import Modal from '@/components/shared/Modal'
import { validateEmail } from '@/helpers/validation'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const email = ref('')

const showInviteUserModal = computed(
  () => store.state.modals.showInviteUserModal
)
const usersInvited = computed(() => store.state.invitations.usersInvited)

const userIsInvited = computed(() => {
  if (!usersInvited.value) {
    return []
  }
  const isInvited = usersInvited.value.find(
    (u) => u.attributes.email === email.value
  )
  return isInvited
})
const disableButton = computed(() => {
  if (userIsInvited.value !== undefined) return true
  if (validateEmail(email.value)) return false
  return true
})

const sendInvitation = (payload) =>
  store.dispatch('invitations/sendInvitation', payload)
const toggleInviteUserModal = () => store.commit('modals/toggleInviteUserModal')

function onSend(userEmail) {
  sendInvitation({ email: userEmail })
  email.value = ''
  toggleInviteUserModal()
}

function handleEmail({ value }) {
  email.value = value
}
</script>
