<template>
  <Modal
    close
    headerBorder
    footerBorder
    :show-modal="showBulkUpdateSegments"
    :loading="loading"
    @close="handleCancel"
  >
    <template #header>
      <TitleRegular neutral :title="getModalTitle" />
    </template>
    <template #body>
      <div class="flex flex-col gap-6 mt-6">
        <TextTiny lightNeutral>{{
          $t('customer.training_data.ai_training.update.info')
        }}</TextTiny>
        <Select :title="$t('shared.label.status')">
          <SelectSelectionCustom
            data-cy="update_status_select"
            placeholder="-"
            :disabled="deleteChecked"
            :value="
              selected.status
                ? $t(`customer.training_data.ai_training.${selected.status}`)
                : ''
            "
          >
            <SelectOptionCustom
              value="used"
              :selected="selected.status === 'used'"
              @inputChange="onStatusSelect"
            >
              <span>{{ $t('customer.training_data.ai_training.used') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="not_used"
              :selected="selected.status === 'not_used'"
              @inputChange="onStatusSelect"
            >
              <span>{{
                $t('customer.training_data.ai_training.not_used')
              }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
        <Select :title="$t('customer.lang_assets.segment.usage')">
          <SelectSelectionCustom
            data-cy="update_usage_select"
            placeholder="-"
            :disabled="deleteChecked"
            :value="selected.usage ? getUsageValue : ''"
          >
            <SelectOptionCustom
              value="global"
              :selected="selected.usage === 'global'"
              @inputChange="onUsageSelect"
            >
              <span>{{ $t('customer.training_data.ai_training.global') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              :value="currentAccountId"
              :selected="selected.usage === currentAccountId"
              @inputChange="onUsageSelect"
            >
              <span>{{ capitalizeFirstLetter(currentAccountId) }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              v-for="(account, i) in allSubAccounts"
              :key="i"
              :value="account.team_identifier"
              :selected="selected.usage === account.team_identifier"
              @inputChange="onUsageSelect"
            >
              <span>{{ capitalizeFirstLetter(account.team_identifier) }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
        <div class="w-full grid grid-cols-[max-content_1fr]">
          <CheckboxBtn
            red
            class="mt-0.5"
            :is-active="deleteChecked"
            @click="hanldeDelete"
          />
          <div class="flex flex-col gap-1">
            <TextSmall errorDark medium>{{
              $t('customer.training_data.ai_training.update.delete')
            }}</TextSmall>
            <TextTiny error>{{
              $t('customer.training_data.ai_training.update.delete.info')
            }}</TextTiny>
            <Label
              v-if="deleteChecked"
              required
              :title="
                $t('customer.training_data.ai_training.update.delete.confirm')
              "
            >
              <InputField
                id="segments_count_input"
                :placeholder="
                  $t(
                    'customer.training_data.ai_training.update.delete.confirm.placeholder'
                  )
                "
                type="number"
                @inputChange="handleSegmentsCount"
              />
            </Label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="$t('shared.button.update')"
          :disabled="disableBtn"
          class="flex-1"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="handleCancel"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import Label from '@/components/shared/label/Label'
import Modal from '@/components/shared/Modal'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import { capitalizeFirstLetter } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const deleteChecked = ref(false)
const segmentCount = ref(null)
const selected = ref({
  status: '',
  usage: ''
})

const showBulkUpdateSegments = computed(
  () => store.state.modals.showBulkUpdateSegments
)

const allSubAccounts = computed(() => store.state.account.allSubAccounts)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const disableBtn = computed(
  () =>
    (deleteChecked.value && segmentCount.value != 2) ||
    (!deleteChecked.value && (!selected.value.status || !selected.value.usage))
)

const getModalTitle = computed(() =>
  $t('customer.training_data.ai_training.update_title', {
    count: 2,
    source: 'EN_US',
    target: 'FR'
  })
)
const getUsageValue = computed(() => {
  switch (selected.value.usage) {
    case 'all':
      return $t('customer.lang_assets.content_type.all')
    case 'global':
      return $t('customer.training_data.ai_training.global')
    default:
      return capitalizeFirstLetter(selected.value.usage)
  }
})

const toggleBulkUpdateSegments = () =>
  store.commit('modals/toggleBulkUpdateSegments')

function onStatusSelect(value) {
  selected.value.status = value
}
function onUsageSelect(value) {
  selected.value.usage = value
}
function hanldeDelete() {
  resetSelected()
  deleteChecked.value = !deleteChecked.value
}
function handleSegmentsCount({ value }) {
  segmentCount.value = value
}

function resetSelected() {
  selected.value = {
    status: '',
    usage: ''
  }
}
function handleCancel() {
  resetSelected()
  deleteChecked.value = false
  segmentCount.value = null
  toggleBulkUpdateSegments()
}
</script>
