<template>
  <Modal
    close
    headerBorder
    footerBorder
    :show-modal="showGenerateAIData"
    :loading="loading"
    @close="toggleGenerateAiData"
  >
    <template #header>
      <TitleRegular
        neutral
        :title="$t('customer.training_data.ai_training.generate_title')"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-8 mt-6">
        <div>
          <TextTiny lightNeutral class="mb-6">{{
            $t('customer.training_data.ai_training.generate.info')
          }}</TextTiny>
          <div>
            <RadioButton
              :label="`${$t('shared.label.select')} ${$t('customer.training_data.ai_training.generate.ai_model')}`"
              :active="selectModel"
              @check="handleToggle(true)"
            />
            <Select v-if="selectModel" class="ml-4 mt-1">
              <SelectSelectionCustom
                data-cy="ai_model_select"
                :value="selected.model"
              >
                <SelectOptionCustom
                  v-for="model in aiModels"
                  :key="model"
                  :value="model"
                  :selected="selected.model === model"
                  @inputChange="onModelChange"
                >
                  <span>{{ model }}</span>
                </SelectOptionCustom>
              </SelectSelectionCustom>
            </Select>
          </div>
          <div class="flex items-center gap-2 my-4">
            <TextTiny class="uppercase" lightNeutral>{{
              $t('shared.label.or')
            }}</TextTiny>
            <hr class="text-neutral-150 w-full" />
          </div>
          <div>
            <RadioButton
              :label="
                $t(
                  'customer.training_data.ai_training.generate.custom_ai_model'
                )
              "
              :active="!selectModel"
              @check="handleToggle(false)"
            />
            <InputField
              v-if="!selectModel"
              class="ml-4 mt-1"
              :placeholder="
                $t('customer.training_data.ai_training.generate.ai_model')
              "
              @inputChange="({ value }) => onModelChange(value)"
            />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex items-center justify-between mb-1">
            <Label
              :title="
                $t('customer.training_data.ai_training.generate.temperature')
              "
              class="pt-2"
            />
            <InputField
              id="temperature_input"
              input-appearance-none
              :value="selected.temperature"
              :min="0.6"
              :max="1.2"
              :step="0.01"
              centered
              type="number"
              @inputChange="handleTemperature"
            />
          </div>
          <RangeSlider
            id="temperature_slider"
            update-key="ai_temperature"
            :min="0.6"
            :max="1.2"
            :step="0.01"
            :value="selected.temperature"
            @updateValue="rangeTemperature"
          />
          <div class="flex items-center justify-between">
            <TextTiny>{{ $t('shared.caas.sidebar.low') }}</TextTiny>
            <TextTiny>{{ $t('shared.caas.sidebar.high') }}</TextTiny>
          </div>
        </div>
        <Label
          :title="$t('customer.training_data.ai_training.generate.segments')"
        >
          <InputField
            id="segments_input"
            :value="selected.segmentsCount"
            :min="1"
            :max="10"
            type="number"
            @inputChange="handleSegmentsCount"
          />
        </Label>
        <div class="w-full grid grid-cols-[max-content_1fr]">
          <CheckboxBtn
            class="mt-0.5"
            :is-active="selected.globalUse"
            @click="handleGlobalUse"
          />
          <div class="flex flex-col gap-1">
            <TextSmall neutral medium>{{
              $t('customer.training_data.ai_training.generate.global_use')
            }}</TextSmall>
            <TextTiny lightNeutral>{{
              $t('customer.training_data.ai_training.generate.global_use.info')
            }}</TextTiny>
          </div>
        </div>
        <Textarea
          data-cy="system_prompt"
          :placeholder="
            $t(
              'customer.training_data.ai_training.generate.global_use.system_prompt'
            )
          "
          white
          size="maxContent"
          :value="selected.systemPrompt"
          @inputChange="onSystemPrompt"
        >
          <template #suffix>
            <font-awesome-icon icon="pen-to-square" class="text-neutral-200" />
          </template>
        </Textarea>
        <div class="bg-neutral-50 rounded-lg p-3">
          <Textarea
            data-cy="user_prompt"
            :placeholder="
              $t(
                'customer.training_data.ai_training.generate.global_use.user_prompt'
              )
            "
            white
            size="md"
            :value="selected.userPrompt"
            @inputChange="onUserPrompt"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="$t('customer.training_data.ai_training.generate.btn')"
          class="flex-1"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="toggleGenerateAiData"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import RadioButton from '@/components/shared/btn/RadioButton'
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import Label from '@/components/shared/label/Label'
import Modal from '@/components/shared/Modal'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import RangeSlider from '@/components/shared/sliders/RangeSlider'
import Textarea from '@/components/shared/textarea/Textarea'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const aiModels = ref(['GPT-2', 'GPT-3', 'GPT-4', 'GPT-4o', 'o1'])

const selectModel = ref(true)
const selected = ref({
  model: 'GPT-4o',
  temperature: 1,
  segmentsCount: 5,
  globalUse: false,
  systemPrompt: '',
  userPrompt: ''
})

const showGenerateAIData = computed(() => store.state.modals.showGenerateAIData)
const toggleGenerateAiData = () => store.commit('modals/toggleGenerateAiData')

function handleToggle(select) {
  selectModel.value = select
  if (!select) selected.value.model = ''
  else selected.value.model = 'GPT-4o'
}
function onModelChange(model) {
  selected.value.model = model
}

function handleTemperature({ value }) {
  selected.value.temperature = value
}
function rangeTemperature(val) {
  selected.value.temperature = val
}

function handleSegmentsCount({ value }) {
  selected.value.segmentsCount = value
}

function handleGlobalUse() {
  selected.value.globalUse = !selected.value.globalUse
}

function onSystemPrompt(value) {
  selected.value.systemPrompt = value
}
function onUserPrompt(value) {
  selected.value.userPrompt = value
}
</script>
