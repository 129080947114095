<template>
  <Modal
    :show-modal="showProjectModal"
    :loading="loading"
    @close="toggleProjectModal"
  >
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('customer.project_modal.title')"
      />
      <TextRegular
        class="mt-3 cursor-pointer w-fit text-primary-100 hover:underline"
        data-cy="toggle_tutorial"
        @click="toggleTutorialModal"
      >
        {{ tutorialText }}
      </TextRegular>
    </template>
    <template #body>
      <div v-if="showVideoTutorial">
        <iframe
          data-cy="tutorial_video"
          class="w-full h-[500px]"
          src="https://www.youtube.com/embed/1-NhmaFBYBw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div v-else-if="account.attributes">
        <div class="flex flex-col gap-4">
          <Select required :title="$t('shared.label.select_workflow')">
            <SelectSelectionCustom
              data-cy="select_flow"
              :allowSearch="validWorkflows.length > 10"
              :value="selected.process"
              @search="handleProcessSearch"
            >
              <SelectOptionCustom
                v-for="workflow in filteredWorkflows"
                :id="workflow.id"
                :key="workflow.id"
                :ref="
                  (el) => {
                    processOptionsRef[workflow.attributes.display_name] = el
                  }
                "
                :value="workflow.attributes.display_name"
                :selected="
                  selected.process === workflow.attributes.display_name
                "
                @inputChange="onSelectedProcess"
              >
                <span>{{ workflow.attributes.display_name }}</span>
              </SelectOptionCustom>
            </SelectSelectionCustom>
          </Select>
          <!-- If user has team of translator, at least one translator -->
          <template v-if="enableTranslation">
            <Label optional :title="$t('shared.placeholder.project_name')">
              <InputField
                data-cy="project_name"
                :placeholder="$t('shared.placeholder.project_name')"
                :red-border="projectNameAlreadyExists"
                @inputChange="getProjectName"
              />
              <div
                v-if="projectNameAlreadyExists"
                class="text-xs mt-1 text-error-100"
              >
                {{ $t('customer.project.modal.name_exists') }}
                <font-awesome-icon
                  icon="fa-solid fa-triangle-exclamation"
                  class="text-error-100"
                />
              </div>
            </Label>
            <div
              v-if="onShowDeadline"
              class="flex flex-col relative items-baseline"
            >
              <div class="flex gap-2 items-center">
                <BtnSecondary
                  class="!p-0 text-sm"
                  data-cy="toggle_deadline"
                  :title="$t('shared.label.click_here')"
                  @click="show.deadline = !show.deadline"
                />
                <TextTiny>
                  {{ $t('customer.project_modal.preferred_deadline.set') }}
                  <i
                    class="fas fa-question-circle relative cursor-pointer text-neutral-200"
                    @mouseenter="show.tooltip = true"
                    @mouseleave="show.tooltip = false"
                  />
                </TextTiny>
              </div>
              <TextTiny
                v-if="show.tooltip"
                class="text-neutral-200 leading-relaxed absolute block bg-white z-[9] p-4 rounded top-[40px] left-0 shadow-lg text-center"
              >
                {{ $t('customer.project_modal.preferred_deadline.text') }}
              </TextTiny>
              <div v-if="show.deadline" class="flex flex-col w-full mt-2">
                <TextTiny>
                  {{
                    $t('customer.project_modal.preferred_deadline_note')
                  }}</TextTiny
                >
                <div class="flex justify-between pt-3">
                  <InputDate
                    data-cy="input_date"
                    disable-past-dates
                    :placeholder="deadlineDate"
                    @inputChange="handleDate"
                  />
                  <Select>
                    <SelectSelectionCustom
                      data-cy="time_select"
                      allowMultiline
                      :placeholder="$t('shared.label.choose_time')"
                      :value="selected.deadline.time || ''"
                    >
                      <SelectOptionCustom
                        v-for="time in times"
                        :key="time.name"
                        :value="time.name"
                        :selected="selected.deadline.time === time.name"
                        @inputChange="handleTime"
                      >
                        <span>{{ time.name }}</span>
                      </SelectOptionCustom>
                    </SelectSelectionCustom>
                  </Select>
                </div>
              </div>
            </div>
            <Select required :title="$t('shared.label.source_lang')">
              <SelectSelectionCustom
                data-cy="select_source"
                :allowSearch="sourceList.length > 10"
                :placeholder="$t('shared.label.select_source')"
                :value="
                  selected.sourceCode
                    ? $t(`shared.languages.${selected.sourceCode}`)
                    : ''
                "
                @search="handleSourceSearch"
              >
                <SelectOptionCustom
                  v-for="source in filteredSources"
                  :key="source.code"
                  :value="source.name"
                  :selected="selected.sourceCode === source.code"
                  @inputChange="onSelectedSource($event, source.code)"
                >
                  <span>{{ $t(`shared.languages.${source.code}`) }}</span>
                </SelectOptionCustom>
              </SelectSelectionCustom>
            </Select>
            <div v-if="selected.process != 'Copy editing'">
              <Label
                required
                :title="$t('shared.label.target_lang')"
                class="mb-1"
              />
              <!-- Target languages select option -->
              <div class="flex gap-3 items-center mb-2">
                <RadioButton
                  id="optional"
                  data-cy="toggle_list_targets"
                  :label="$t('shared.label.select_from_list')"
                  :active="!selectAllTargets"
                  @check="toggleAllTargets(false, false)"
                />
                <RadioButton
                  id="all"
                  data-cy="toggle_all_targets"
                  :label="$t('shared.label.select_all')"
                  :active="selectAllTargets"
                  @check="toggleAllTargets(true, false)"
                />
                <RadioButton
                  v-if="
                    isMachineTranslation &&
                    preselectedLanguages.length > 0 &&
                    account.attributes.subscription.attributes
                      .plan_identifier == 'enterprise'
                  "
                  id="preselected"
                  data-cy="toggle_preselected_targets"
                  :label="$t('shared.button.preselect_languages')"
                  :active="selectPreselected"
                  @check="toggleAllTargets(true, true)"
                />
              </div>
              <!-- Target languages -->
              <Select>
                <SelectSelectionCustom
                  data-cy="select_target"
                  multiselect
                  :allowSearch="targetList.length > 10"
                  :placeholder="$t('shared.label.select_target')"
                  @search="handleTargetSearch"
                  @selectAll="handleSelectTargets"
                  @close="clearIndex()"
                >
                  <SelectOptionCustom
                    v-for="(target, i) in filteredTargets"
                    multiselect
                    :key="target.code"
                    :value="target.name"
                    :selected="selected.targets.includes(target.name)"
                    @inputChange="onSelectedTarget"
                    @shiftSelect="shiftTarget(target, i)"
                  >
                    <span>{{ $t(`shared.languages.${target.code}`) }}</span>
                  </SelectOptionCustom>
                  <template #multiselect v-if="selected.targets.length > 0">
                    <FilterWrap data-cy="selected_targets">
                      <FilterItem
                        v-for="(target, targetIndex) in selected.targets"
                        :key="targetIndex"
                        @remove="onRemoveTag(targetIndex)"
                      >
                        <span>{{ target }}</span>
                      </FilterItem>
                    </FilterWrap>
                  </template>
                </SelectSelectionCustom>
              </Select>
            </div>
            <BtnSecondary
              class="block ml-auto !p-0"
              data-cy="create_folder"
              :title="$t('shared.button.create_folder')"
              @click="show.newFolder = !show.newFolder"
            />
            <div
              v-if="show.newFolder"
              class="flex justify-between items-center gap-6 bg-neutral-50 rouded p-3 py-6"
            >
              <div class="relative w-full">
                <InputField
                  data-cy="folder_name"
                  :placeholder="$t('shared.placeholder.folder_name')"
                  :red-border="folderNameAlreadyExists"
                  :value="folderName"
                  @inputChange="({ value }) => (folderName = value)"
                />
                <div
                  v-if="folderNameAlreadyExists"
                  class="absolute -bottom-[19px] text-xs mt-1 text-error-100"
                >
                  {{ $t('customer.folder.modal.name_exists') }}
                  <font-awesome-icon
                    icon="fa-solid fa-triangle-exclamation"
                    class="text-error-100"
                  />
                </div>
              </div>
              <BtnsWrapper class="flex gap-2 !mt-0">
                <BtnPrimary
                  :title="$t('shared.button.save')"
                  :disabled="folderNameAlreadyExists || !folderName"
                  @click="onCreateNewFolder"
                />
                <BtnSecondary
                  class="!px-2"
                  :title="$t('shared.button.cancel')"
                  @click="
                    () => {
                      show.newFolder = false
                      folderName = ''
                    }
                  "
                />
              </BtnsWrapper>
            </div>
            <Select :title="$t('shared.placeholder.project_folder')">
              <SelectSelectionCustom
                data-cy="folder_select"
                :allowSearch="allFolders.length > 10"
                :placeholder="$t('customer.label.select_folder')"
                :value="selected.folder.name || ''"
                @search="handleFolderSearch"
              >
                <SelectOptionCustom
                  v-for="(folder, i) in filteredFolders"
                  :key="folder.attributes.created_at + i"
                  :value="folder.attributes.name"
                  :selected="selected.folder.name === folder.attributes.name"
                  @inputChange="onSelectedFolder"
                >
                  <span>{{ folder.attributes.name }}</span>
                </SelectOptionCustom>
              </SelectSelectionCustom>
            </Select>
            <DragAndDrop
              required
              :title="$t('shared.label.upload_file')"
              :custom-supported-formats="supportedFiles"
              :text="$t('shared.placeholder.upload')"
              @filesAdded="filesAdded"
            />
            <Label
              v-if="selected.process != 'Machine Translation' && !selected.isAi"
              optional
              :title="$t('shared.label.specific_instructions.customer')"
            >
              <Textarea
                data-cy="supplier_instructions"
                :value="instructionText"
                vertical
                :placeholder="$t('shared.label.specific_instructions_text')"
                @inputChange="getInstructions"
              />
            </Label>
          </template>
          <template v-else>
            <InfoBlock>
              <TextRegular>
                <i18n-t
                  keypath="customer.create_project.modal.missing_translators"
                  tag="p"
                >
                  <template #here>
                    <router-link
                      :to="{ name: 'clientTeamStepOne' }"
                      @click="toggleProjectModal"
                    >
                      <span class="text-primary-100 hover:underline">{{
                        $t('shared.button.here')
                      }}</span>
                    </router-link>
                  </template>
                </i18n-t>
              </TextRegular>
            </InfoBlock>
          </template>
        </div>
      </div>
    </template>
    <template v-if="!showVideoTutorial" #footer>
      <RequiredInfoLabel />
      <BtnsWrapperVertical>
        <BtnPrimary
          data-cy="create_project_button"
          data-intercom-target="Create project"
          class="w-full"
          :title="$t('shared.button.create_project')"
          :disabled="disableButton"
          @click="onCreateProject"
        />
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          @click="toggleProjectModal"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import RadioButton from '@/components/shared/btn/RadioButton'
import DragAndDrop from '@/components/shared/dragAndDrop/DragAndDrop'
import FilterItem from '@/components/shared/filter/new/FilterItem'
import FilterWrap from '@/components/shared/filter/new/FilterWrap'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InfoBlock from '@/components/shared/InfoBlock'
import InputDate from '@/components/shared/input/InputDate'
import InputField from '@/components/shared/input/InputField'
import Label from '@/components/shared/label/Label'
import RequiredInfoLabel from '@/components/shared/label/RequiredInfoLabel'
import Modal from '@/components/shared/Modal'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import Textarea from '@/components/shared/textarea/Textarea'
import { useShiftSelect } from '@/helpers/select'
import i18n from '@/i18n'
import moment from 'moment'
import _ from 'underscore'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const folderName = ref('')
const selected = ref({
  process: 'Human translation',
  source: null,
  sourceCode: null,
  target: null,
  projectName: '',
  targets: [],
  files: [],
  isAi: false,
  folder: {
    name: null,
    id: null
  },
  deadline: {
    date: null,
    time: null,
    dateTime: null
  }
})
const show = ref({
  deadline: false,
  newFolder: false,
  tooltip: false
})
const selectAllTargets = ref(false)
const selectPreselected = ref(false)
const showVideoTutorial = ref(false)
const instructionText = ref(null)
const processOptionsRef = ref({})

const processSearch = ref(null)
const sourceSearch = ref(null)
const targetSearch = ref(null)
const folderSearch = ref(null)

const { selectedItems, shiftSelect, clearIndex } = useShiftSelect()

const allProjects = computed(() => store.state.workspace.allProjects)
const currentUser = computed(() => store.state.workspace.currentUser)
const currentAccount = computed(() => store.state.workspace.currentAccount)
const showProjectModal = computed(() => store.state.modals.showProjectModal)
const languages = computed(() => store.state.account.languages)
const accountLangPair = computed(() => store.state.account.accountLangPair)
const account = computed(() => store.state.account.account)
const allFolders = computed(() => store.state.folder.allFolders)
const translators = computed(() => store.state.translators.translators)
const currentFolder = computed(() => store.state.workspace.currentFolder)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const projectNameAlreadyExists = computed(() => {
  return (
    !loading.value &&
    allProjects.value.some(
      (obj) =>
        obj.attributes.name.toUpperCase() ===
        selected.value.projectName.toUpperCase()
    )
  )
})

const processIdentifier = computed(() => {
  const process = processOptionsRef.value.find(
    (p) => p.attributes.display_name === selected.value.process
  )
  if (process) {
    return process.attributes.identifier
  } else {
    return ''
  }
})
const isMachineTranslation = computed(() => {
  const workflow = account.value.attributes.workflows.find(
    (w) => w.attributes.display_name === selected.value.process
  ).attributes
  return workflow.is_machine_translation && !workflow.is_ai_translation
})
const validWorkflows = computed(() => {
  const aiFlowAllowed =
    account.value.attributes.features.translation_flow === 'ai'
  return account.value.attributes.workflows.filter(
    (w) =>
      !w.attributes.has_copywriting_steps &&
      (aiFlowAllowed || w.attributes.identifier !== 'ai+translation')
  )
})
const filteredWorkflows = computed(() => {
  return validWorkflows.value
    .filter(
      (v) =>
        !processSearch.value ||
        v.attributes.display_name
          .toLowerCase()
          .includes(processSearch.value.toLowerCase())
    )
    .sort((a, b) =>
      a.attributes.display_name.localeCompare(b.attributes.display_name)
    )
})

const onShowDeadline = computed(() => {
  return selected.value.process != 'Translate Yourself'
})
const deadlineDate = computed(() => {
  if (selected.value.deadline.date) return selected.value.deadline.date
  return $t('shared.label.choose_date')
})
const times = computed(() => {
  let times = []
  for (let i = 0; i < 24; i++) {
    times.push({
      value: i,
      name: `${i < 10 ? '0' : ''}${i}:00`
    })
  }
  return times
})

const enableTranslation = computed(() => {
  const isAiWorkflow = account.value.attributes.workflows.find(
    (w) => w.attributes.display_name === selected.value.process
  ).attributes.is_ai_translation
  return (
    translators.value.length > 0 ||
    selected.value.process === 'Machine Translation' ||
    isAiWorkflow
  )
})

const showAllLanguages = computed(() => {
  return (
    processIdentifier.value === 'self+machine_translation' ||
    !accountLangPair.value[processIdentifier.value]
  )
})
const sourceList = computed(() => {
  if (showAllLanguages.value) return languages.value.source
  return accountLangPair.value[processIdentifier.value]
})
const filteredSources = computed(() => {
  if (!sourceSearch.value) return sourceList.value
  return sourceList.value.filter((lang) =>
    $t(`shared.languages.${lang.code}`)
      .toLowerCase()
      .includes(sourceSearch.value.toLowerCase())
  )
})

const preselectedLanguages = computed(() => {
  const list =
    selected.value.source &&
    Object.values(account.value.attributes.language_pairs.translation).find(
      (source) => source.name === selected.value.source
    )
  if (list) {
    return list.target_languages
  } else {
    return []
  }
})
const targetList = computed(() => {
  if (showAllLanguages.value && !selectPreselected.value)
    return languages.value.target
  const list = !selectPreselected.value
    ? selected.value.source &&
      Object.values(sourceList.value).find(
        (source) => source.name === selected.value.source
      ).target_languages
    : preselectedLanguages.value
  if (list) {
    return list
  } else {
    return []
  }
})
const filteredTargets = computed(() => {
  if (!targetSearch.value) return targetList.value
  return targetList.value.filter((lang) =>
    $t(`shared.languages.${lang.code}`)
      .toLowerCase()
      .includes(targetSearch.value.toLowerCase())
  )
})

const supportedFiles = computed(() => {
  const isAiWorkflow = account.value.attributes.workflows.find(
    (w) => w.attributes.display_name === selected.value.process
  ).attributes.is_ai_translation
  const aiSupported =
    '.docx, .xlsx, .xlf, .pptx, .ott, .ods, .ots, .otp, .html, .xhtml, .sdlxliff, .mxliff, .xliff, .po, .ttx, .mif, .idml, .icml, .dita, .csv, .tsv, .xml, .dtd, .json, .yaml, .txt, .properties, .resx, .strings, .srt, .wix'
  return isAiWorkflow ? aiSupported : ''
})

const folderNameAlreadyExists = computed(() => {
  return (
    !loading.value &&
    allFolders.value.some(
      (obj) =>
        obj.attributes.name.toUpperCase() === folderName.value.toUpperCase()
    )
  )
})
const filteredFolders = computed(() => {
  return allFolders.value
    .filter(
      (f) =>
        !folderSearch.value ||
        f.attributes.name
          .toLowerCase()
          .includes(folderSearch.value.toLowerCase())
    )
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
})

const disableButton = computed(() => {
  if (selected.value.process == 'Copy editing') {
    return !selected.value.source || projectNameAlreadyExists.value
  } else if (
    selected.value.files.length > 0 &&
    selected.value.targets.length > 0 &&
    selected.value.source &&
    !loading.value &&
    !projectNameAlreadyExists.value
  ) {
    return false
  } else {
    return true
  }
})

const tutorialText = computed(() => {
  return showVideoTutorial.value
    ? $t('shared.button.go_back')
    : $t('customer.project_modal.video_btn')
})

const createNewFolder = (payload) =>
  store.dispatch('workspace/createNewFolder', payload)
const createProject = (payload) =>
  store.dispatch('projects/createProject', payload)
const reportTrackingEvent = (payload) =>
  store.dispatch('teams/reportTrackingEvent', payload)
const toggleProjectModal = () => store.commit('modals/toggleProjectModal')

function getProjectName(project) {
  selected.value.projectName = project.value
}

// Workflow
function onSelectedProcess(processType) {
  const selectedFlow = validWorkflows.value.find(
    (flow) => flow.attributes.display_name === processType
  )

  selected.value.process = processType
  selected.value.source = null
  selected.value.sourceCode = null
  selected.value.target = null
  selected.value.targets = []
  selected.value.isAi = selectedFlow.attributes.is_ai_translation
  selectAllTargets.value = false
}
const handleProcessSearch = _.debounce(function (value) {
  processSearch.value = value
}, 300)

// Deadline
function handleDate(date) {
  let format = moment(date).format('YYYY-MM-DD')
  selected.value.deadline.date = format
}
function handleTime(value) {
  selected.value.deadline.time = value
}

// Source language
function onSelectedSource(sourceType, sourceCode) {
  selected.value.source = sourceType
  selected.value.sourceCode = sourceCode
  selected.value.target = null
  selected.value.targets = []
  selectAllTargets.value = false
  selectPreselected.value = false
}
const handleSourceSearch = _.debounce(function (value) {
  sourceSearch.value = value
}, 300)

// Target languages
function onSelectedTarget(targetType) {
  if (selected.value.targets.includes(targetType)) return
  selected.value.targets.push(targetType)
  selected.value.target = targetType
}
function onRemoveTag(i) {
  selected.value.targets.splice(i, 1)
}
function toggleAllTargets(all, isPreselect) {
  selectPreselected.value = false
  if (isPreselect) {
    selectPreselected.value = true
  }
  if (all && targetList.value) {
    selected.value.targets = targetList.value.map((target) => target.name)
  } else {
    selected.value.targets = []
  }
  selectAllTargets.value = all
}
const handleTargetSearch = _.debounce(function (value) {
  targetSearch.value = value
}, 300)
function handleSelectTargets(selectAll) {
  if (selectAll) {
    selected.value.targets = [
      ...selected.value.targets,
      ...filteredTargets.value
        .map((lang) => lang.name)
        .filter((name) => !selected.value.targets.includes(name))
    ]
  } else selected.value.targets = []
}
function shiftTarget(value, i) {
  shiftSelect({
    data: filteredTargets.value,
    selected: selected.value.targets,
    value,
    index: i,
    getItemValue: (item) => item.name
  })
  selected.value.targets = [...selectedItems.value]
}

function filesAdded(files) {
  selected.value.files = files
}

// Folder
function onSelectedFolder(folder) {
  let folders = allFolders.value
  let selectedFolder
  folders.forEach((obj) => {
    if (obj.attributes.name == folder) {
      selectedFolder = {
        name: obj.attributes.name,
        id: obj.id
      }
      return
    }
  })
  selected.value.folder = selectedFolder
}
function onCreateNewFolder() {
  createNewFolder({ folderName: folderName.value }).finally(() => {
    show.value.newFolder = false
    folderName.value = ''
  })
}
const handleFolderSearch = _.debounce(function (value) {
  folderSearch.value = value
}, 300)

function getInstructions(value) {
  instructionText.value = value
}

async function pushGA4FunnelEvent() {
  if (
    currentAccount?.value?.tracking?.active_action_at === null &&
    isMachineTranslation.value
  ) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'funnel',
      funnel_name: '3_freemium_active',
      first_name: currentUser?.value?.attributes?.first_name || '',
      last_name: currentUser?.value?.attributes?.last_name || '',
      phone: currentUser?.value?.attributes?.phone || '',
      email: currentUser?.value?.attributes?.email || '',
      team_name: currentAccountId.value,
      user_id: currentUser.value.id,
      action: 'Created MT project',
      nonInteraction: true
    })
    await reportTrackingEvent({ isPaidAction: false })
  }
}
function onCreateProject() {
  loading.value = true
  const data = {
    workflow_id:
      processOptionsRef.value[selected.value.process].$el.getAttribute('id'),
    files: selected.value.files,
    source: selected.value.sourceCode,
    folderId: selected.value.folder.id,
    projectName: selected.value.projectName,
    deadline: selected.value.deadline.dateTime
      ? moment(selected.value.deadline.dateTime)
          .subtract(moment(selected.value.deadline.dateTime).utcOffset(), 'm')
          .format('YYYY-MM-DD HH:mm:ss')
      : null,
    instructions: instructionText.value,
    targets: []
  }
  if (selected.value.process == 'Copy editing') {
    selected.value.targets = ['English (United States)']
  }
  languages.value.target.forEach((lang) => {
    if (selected.value.targets.includes(lang.name)) {
      return data.targets.push(lang.code)
    }
  })
  createProject(data)
    .then(() => {
      pushGA4FunnelEvent()
    })
    .finally(() => {
      loading.value = false
    })
}

function toggleTutorialModal() {
  showVideoTutorial.value = !showVideoTutorial.value
}

watch(
  () => selected.value.targets,
  (targets) => {
    if (targets.length === 0) {
      selected.value.target = null
    }
  }
)
watch(
  () => selected.value.deadline,
  (targets) => {
    if (targets.date && targets.time) {
      for (const time of times.value) {
        if (time.name == targets.time) {
          const timeAndDateFormat = moment(targets.date)
            .set('hour', time.value)
            .format('YYYY-MM-DD HH:mm:ss')
          selected.value.deadline.dateTime = timeAndDateFormat
        }
      }
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => showProjectModal.value,
  (isOpen) => {
    if (!isOpen) {
      loading.value = false
      folderName.value = ''
      selected.value = {
        process: 'Human translation',
        source: null,
        sourceCode: null,
        target: null,
        projectName: '',
        targets: [],
        files: [],
        isAi: false,
        folder: {
          name: null,
          id: null
        },
        deadline: {
          date: null,
          time: null,
          dateTime: null
        }
      }
      show.value = {
        deadline: false,
        newFolder: false,
        tooltip: false
      }
      selectAllTargets.value = false
      selectPreselected.value = false
      showVideoTutorial.value = false
      instructionText.value = null
    }
    processOptionsRef.value = validWorkflows.value
  }
)
watch(
  () => currentFolder.value,
  (folder) => {
    if (Object.keys(folder).length) {
      onSelectedFolder(folder.attributes.name)
    }
  }
)
</script>
